// Opciones datatable

$(document).on('turbolinks:load', function() {
    //$.fn.dataTable.moment( 'DD-MM-YYYY - HH:mm' );
    $('#datatable1').DataTable( {
       "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
        "order": [[ 2, "desc" ]],
         "columnDefs": [{
        "orderable": false, "targets": [  1, 2, 4, 5, 6, 7 ]
        }],
        "stateSave": true
    });

    $('#datatable2').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX":true,
         "language": {

            "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_ entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtered from _MAX_ total entries)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },        
        "paging":  true,
        "bFilter": true,
        "info":   true,
        "order": [[ 0, "asc" ]],
        "columnDefs": [{
        "orderable": false, "targets": [ 1, 2, 3, 4]
        }],
        "stateSave": true

    });
    $('#datatable3').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX":true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
        "order": [[ 1, "desc" ]],
         "columnDefs": [{
        "orderable": false, "targets": [ 0, 6, 7, 8 ]
        }]
    });
    $('#datatable4').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
        "order": [[ 3, "asc" ]],
         "columnDefs": [{
        "orderable": false, "targets": [  1, 5, 6 ]
        }]
    });
    $('#datatable5').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
        "order": [[ 0, "asc" ]],
         "columnDefs": [{
        "orderable": false, "targets": [ 2, 3, 5, 6 ]
        }]
    });
    $('#datatable6').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
        "order": [[ 4, "desc" ]],
         "columnDefs": [{
        "orderable": false, "targets": [ 2, 3, 5, 6 ]
        }]
    });
    $('#datatable7').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
         "columnDefs": [{
        "orderable": false, "targets": [  7, 8 ]
        }]
    });
    $('#datatable8').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
         "columnDefs": [{
        "orderable": false, "targets": [ 1, 4, 6, 8, 9 ]
        }]
    });
    $('#datatable9').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
         "columnDefs": [{
        "orderable": false, "targets": [ 4 ],
        "render": function ( data, type, row ) {
            return data.length > 30 ?
                data.substr( 0, 30 ) +'…' :
                data;}
        }]
    });
    $('#datatable10').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
        "columnDefs": [{
            "orderable": false, "targets": [ 4 ],
            "render": function ( data, type, row ) {
                return data.length > 30 ?
                    data.substr( 0, 30 ) +'…' :
                    data;}
            }]
    });
    $('#datatable11').DataTable( {
        "scrollY": '50vh',
        "scrollCollapse": true,
        "scrollX": true,
        "language": {
             "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No hay resultados",
            "info": "Mostrando_PAGE_ de _PAGES_",
            "infoEmpty": "No hay datos disponibles",
            "infoFiltered": "(filtrado de  _MAX_ registros totales)",
            "emptyTable":     "No hay datos disponibles en la tabla",
            "info":           "Mostrando  _START_ a _END_ de _TOTAL_  entradas",
            "infoEmpty":      "Mostrando 0 de 0 en 0 entradas",
            "infoFiltered":   "(filtrado de  _MAX_ eventos totales)",
            "infoPostFix":    "",
            "thousands":      ",",
            "lengthMenu":     "Mostrando _MENU_ entradas",
            "loadingRecords": "Cargando...",
            "processing":     "Procesando...",
            "search":         "Buscar:",
            "zeroRecords":    "No se encuentran coincidencias",
            "paginate": {
                "first":      "Primera",
                "last":       "Ultima",
                "next":       "Próxima",
                "previous":   "Anterior"}
        },
        "paging":  true,
        "bFilter": true,
        "info":   true,
         "columnDefs": [{
        "orderable": false, "targets": [ ]
        }]
    });
  
      
    
});    

    
   

   


