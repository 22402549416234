/*$(document).ready(function(){
      $("#btnModal_1").click(function(){
        $("#Modal_1").modal("show");
    });
      $("#btnModal_2").click(function(){
      	$("#Modal_1").modal("show");
        $("#Modal_1").modal("hide");
    });
      $("#btnModal_3").click(function(){
        $("#Modal_2").modal();
    });
      $("#btnModal_4").click(function(){
        $("#Modal_3").modal();
    });
      
  });*/
  /*modal activacion fiesta y bloqueo con otros planes*/
$(document).ready(function(){
  var radio = 1
  $('#othersActive').click(function(){
    if ($(this).is(':checked'))
    {
     radio = 1
    }
  
  });
  $('#patternActive').click(function(){
    if ($(this).is(':checked'))
    {
     radio = 0
    }
  
  });
  $('#greaterActive').click(function(){
    if ($(this).is(':checked'))
    {
     radio = 0
    }
  
  });

  $('#switchActive').on('change', function(e){
   if(e.target.checked &&  radio == 0 ){
     $('#modalActive').modal("show");
   }
   if( radio == 1 )
   {
    $('#modalothersActive').modal("show");
   }
   })
});
