// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "bootstrap";
import "eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker";
import "bootstrap-select/js/bootstrap-select";
import "channels";
import "scss/site";
import "js/site";
import "js/map";
import "js/modal";
import "js/tables";
import "js/emoji";
import "js/emojionearea";
import "bootstrap-filestyle/src/bootstrap-filestyle";
import "moment/dist/moment";
import "moment/dist/locale/es";
import "datatables.net-bs4/js/dataTables.bootstrap4.min";
//require('jquery')


global.$ = jQuery

Rails.start()
Turbolinks.start()
ActiveStorage.start()

//Images
const images = require.context("../images/static", true);
const imagePath = (name) => images(name, true);
