
window.initMap = function (lat, lng) {
    var myLatlng = new google.maps.LatLng(lat, lng);
  
    var myOptions = {
      zoom: 15,
      minZoom:13,
      center: myLatlng,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    map = new google.maps.Map(document.getElementById("map"), myOptions);
    var marker = new google.maps.Marker({
        position: myLatlng,
        draggable: true,
        map: map,
    });
   var searchBox = new google.maps.places.SearchBox(document.getElementById('mapsearch')
     ); 
  
    map.addListener('bounds_changed', function() {
      searchBox.setBounds(map.getBounds());
    });

    
    google.maps.event.addListener(searchBox, 'places_changed', function(){

      var places = searchBox.getPlaces();
      var bounds = new google.maps.LatLngBounds();
      var i, place;

      for(i = 0; place = places[i]; i++) {
        bounds.extend(place.geometry.location);
        marker.setPosition(place.geometry.location);
      }
   
      map.fitBounds(bounds);
      map.setZoom(18);



    });
  google.maps.event.addListener(marker,'position_changed', function() {

         document.getElementById("latbox").value = this.getPosition().lat();
        document.getElementById("lngbox").value = this.getPosition().lng();
    });
       
    

    
}