

$(document).on('turbolinks:load', function() {
  
 
    // party date start

    $('#datetimepicker1').datetimepicker({ 
    	viewMode: 'days',
      //defaultDate: new Date (),
        format: 'DD-MM-YYYY HH:mm',
        stepping: '15',
        locale: 'es',
        focusOnShow: false,
        showClose: true,
        widgetPositioning:{ horizontal: 'left',
                            vertical: 'bottom' }
   }); 

    // party date end
    
      $('#datetimepicker2').datetimepicker({
      viewMode: 'days',
      format: 'DD-MM-YYYY 23:59:59',
      stepping: '15',
      locale: 'es',
      focusOnShow: false,
      showClose: true,
      widgetPositioning:{ horizontal: 'left',
                          vertical: 'bottom' }
      });

   
    // Lógica para deshabilitar fechas anteriores o iguales a la fecha seleccionada en el datetimepicker de inicio
    $('#datetimepicker1').on('dp.change', function(e) {
      var selectedDate = e.date;
     // var newDate = selectedDate.add(1, 'days');
      $('#datetimepicker2').data('DateTimePicker').minDate(selectedDate);
    });
  

    // event date start  

    $('#datetimepicker3').datetimepicker({
     defaultDate: gon.events,
        locale: 'es',
        stepping: '15',
        showClose: true,
        focusOnShow: false,
        widgetPositioning:{ horizontal: 'left',
                            vertical: 'bottom' }
    });

    // publi (banner) 

    $('#datetimepicker4').datetimepicker({
        
        //defaultDate: '0101-01-01 00:00',
        disabledDates: gon.date,
        viewMode: 'days',
        format: 'DD-MM-YYYY',
        ignoreReadonly: true,
        locale: 'es',
        showClose: true,
        focusOnShow: false,
        widgetPositioning:{ horizontal: 'left',
                            vertical: 'bottom' }
    });
    
    if( /Android|iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
      $('.selectpicker').selectpicker('mobile');
      
    }
    else{
    $('.selectpicker').selectpicker();
    }
  

  //option selectpicker max leght
    $('.ellipsis').each(function () {
        var text = $(this).text();
        if (text.length > 30) {
          text = text.substring(0, 29) + '...';
          $(this).text(text);
        }
      });

    //publicity show-hide

    $(".css-radio").click(function () {
            if ($(this).attr('id') == "arrow_one") {
                $('#publicity_priority').show();
                $('#publicity_date').hide();
                $("#required_date").removeAttr('required');
                
            } else {
                $('#publicity_priority').hide();
                $('#publicity_date').show();
                $('#required_date').attr('required', '');
                
            }
        });
   
});   


  
     
      

