$(document).ready(function() {
	$("#emojionearea1").emojioneArea({
  	
		pickerPosition: "bottom",
    tonesStyle: "bullet",
		events: {
         	keyup: function (editor, event) {d
           		console.log(editor.html());
           		console.log(this.getText());
        	}
    	}
	});
    $("#emojionearea2").emojioneArea({
  	
		pickerPosition: "bottom",
    tonesStyle: "bullet",
		events: {
         	keyup: function (editor, event) {d
           		console.log(editor.html());
           		console.log(this.getText());
        	}
    	}
	});
  
     $('#divOutside').click(function () {
                $('.emojionearea-button').click()
            })
            
            
});
